import React from "react";
import { FcCalendar } from "react-icons/fc";
import { AiFillStar } from "react-icons/ai";

function Blog({ id, title, date, timestamp, image }) {
  return (
    <section className="bg-white px-5 rounded-md shadow-md py-3">
      <div className="space-y-3">
        <h3 className="text-black text-lg font-semibold">{title}</h3>
        <div className=" flex items-center text-sm space-x-3 text-black">
          <FcCalendar className="singleBlog__calendar" />
          <p>{date}</p>
          <AiFillStar className="singleBlog__read" />
          <p className="timestamp">{timestamp} min read</p>
        </div>
        <img src={image} alt="" className="h-32 w-full object-cover" />
      </div>
    </section>
  );
}

export default Blog;
