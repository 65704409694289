import React, { useState } from "react";
import Blog from "../components/Blog";
import blogData from "../handler/blogsData";
import { Link } from "react-router-dom";

function Blogs() {
  const [blogs, setBlogs] = useState(blogData);
  return (
    <section className="w-full mx-auto md:max-w-6xl px-8 py-5 md:py-20">
      <div className="flex flex-col items-center space-y-8">
        <div className="space-y-20">
          <div className="flex flex-col items-center">
            <h2 className="text-center text-3xl mx-auto font-bold">Blogs</h2>
            <hr className="bg-blue h-1 w-24" />
          </div>
          <div className="grid md:grid-cols-2 gap-8 mt-20">
            {blogs.slice(0, 6).map((blog) => (
              <Blog
                key={blog.id}
                id={blog.id}
                title={blog.title}
                date={blog.date}
                timestamp={blog.timestamp}
                image={blog.image}
              />
            ))}
          </div>
        </div>
        <div className="flex justify-center md:justify-end w-full">
          <Link
            to="/projects"
            className="text-sm bg-blue px-8 py-3 rounded-md shadow-md text-black font-semibold inline-block"
          >
            View More
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Blogs;
