import React from "react";

function Skill({ skill }) {
  const { id, title, image, text } = skill;
  return (
    <section className="">
      <div className="flex  space-x-3">
        <img src={image} className="h-14" alt="" />
        <div className="single__skill-info">
          <h2 className="text-lg font-semibold">{title}</h2>
          <p className="text-sm">{text}</p>
        </div>
      </div>
    </section>
  );
}

export default Skill;
