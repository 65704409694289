//Images
import image1 from "../img/image1.png";
import image2 from "../img/image2.jpeg";
import image3 from "../img/image3.png";
import image4 from "../img/image4.jpg";
import image5 from "../img/image5.jpeg";
// Icons

import Angular from "../icons/angular.png";
import Firebase from "../icons/firebase.png";
import Git from "../icons/git.png";
import Gitlab from "../icons/gitlab.png";
import React from "../icons/react.png";
// import Github from "../icons/github.png";
import Java from "../icons/java.png";
import Node from "../icons/nodejs.png";
import MySql from "../icons/mysql.png";
import Mongo from "../icons/mongodb.png";

export default [
  {
    id: 1,
    image: image1,
    title: "Feeling Unproductive? It Might Be a Relationship Problem",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad id possimus aperiam necessitatibus repudiandae. Sit facilis sed voluptate unde dolor?",
    icons: [Angular, Firebase, Git, Gitlab],
  },

  {
    id: 2,
    image: image2,
    title: "The Benefits of Being Unreliable",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Corporis doloremque incidunt maiores repudiandae nemo laboriosam dolorem quaerat quis quae adipisci?",
    icons: [React, Firebase, Git, Gitlab],
  },

  {
    id: 3,
    image: image3,
    title: "Fixing America Starts With How We Live",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas error repellat repudiandae esse minus cum, architecto pariatur cupiditate quaerat voluptate.",
    icons: [Angular, Node, Mongo, Git, Gitlab],
  },
  {
    id: 4,
    image: image4,
    title: "The Substance of Reality",
    text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus illum rem nostrum ducimus hic porro voluptatum dolorem? Fugiat, repellendus ullam?",

    icons: [Java, MySql, Git, Gitlab],
  },
  {
    id: 5,
    image: image5,
    title: "The Real Reason Behind the Great Resignation",
    text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus distinctio commodi minima non dolorum rem, nobis earum accusantium esse sit.",

    icons: [Java, MySql, Git, Gitlab],
  },
];
