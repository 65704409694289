function Footer() {
  return (
    <footer className="py-5">
      <h2 className="text-center text-lg font-semibold text-white">
        ©2022 By Lokendra Dangi
      </h2>
    </footer>
  );
}

export default Footer;
