//Images
import Medcare from "../img/medcare.png";
import TripGuide from "../img/tripguide.png";
import MasterSab from "../img/mastersab.png";
import SmartPasal from "../img/smartpasal.png";

// Icons

import Angular from "../icons/angular.png";
import Firebase from "../icons/firebase.png";
import Git from "../icons/git.png";
import Gitlab from "../icons/gitlab.png";
import React from "../icons/react.png";
// import Github from "../icons/github.png";
import Java from "../icons/java.png";
import Node from "../icons/nodejs.png";
import MySql from "../icons/mysql.png";
import Mongo from "../icons/mongodb.png";

export default [
  {
    id: 1,
    image: Medcare,
    title: "Medcare: An Medicine Donation Application",
    text: "Medcare is medicine donation app that collect a medicine from urban region of country and donate that medicine to rural region of the country. Anyone interest to donate a medicine can simply register to the system and donate their unexpired medicine",
    icons: [Angular, Firebase, Git, Gitlab],
  },

  {
    id: 2,
    image: TripGuide,
    title: "TripGuide: Destination Finder Application",
    text: "Medcare is medicine donation app that collect a medicine from urban region of country and donate that medicine to rural region of the country. Anyone interest to donate a medicine can simply register to the system and donate their unexpired medicine",
    icons: [React, Firebase, Git, Gitlab],
  },

  {
    id: 3,
    image: MasterSab,
    title: "MasterSab: An online learning platform",
    text: "Medcare is medicine donation app that collect a medicine from urban region of country and donate that medicine to rural region of the country. Anyone interest to donate a medicine can simply register to the system and donate their unexpired medicine",
    icons: [Angular, Node, Mongo, Git, Gitlab],
  },
  {
    id: 4,
    image: SmartPasal,
    title: "SmartPasal: Grocery System",
    text: "Medcare is medicine donation app that collect a medicine from urban region of country and donate that medicine to rural region of the country. Anyone interest to donate a medicine can simply register to the system and donate their unexpired medicine",

    icons: [Java, MySql, Git, Gitlab],
  },
];
