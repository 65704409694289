import React from "react";

import Banner from "./Banner";
import Blogs from "./Blogs";
import Projects from "./Projects";
import Skills from "./Skills";
import Tutorials from "./Tutorials";

function Home() {
  return (
    <>
      <Banner />
      <Skills />
      <Projects />
      <Blogs />
      <Tutorials />
    </>
  );
}

export default Home;
