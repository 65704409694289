import twitter from "../img/twitter.svg";
import discord from "../img/discord.svg";
import opensea from "../img/opensea.svg";
import instagram from "../img/instagram.svg";
export const links = [
  {
    id: 1,
    url: "about",
    text: "About",
  },

  {
    id: 2,
    url: "roadmap",
    text: "Roadmap",
  },
  {
    id: 3,
    url: "faq",
    text: "FAQs",
  },
  {
    id: 4,
    url: "team",
    text: "Team",
  },
];

export const social = [
  {
    id: 1,
    url: "https://twitter.com/Lokey6475",
    icon: twitter,
  },
  {
    id: 2,
    url: "https://discord.gg/cTZePMUY",
    icon: discord,
  },
  {
    id: 3,
    url: "https://www.instagram.com/lokey_dangi/",
    icon: instagram,
  },

  {
    id: 3,
    url: "https://discord.gg",
    icon: opensea,
  },
];
