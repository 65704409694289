export default [
  {
    id: 1,
    title: "Why should i learn react first ?",
    date: "2020-01-15",
    timestamp: 10,
    image:
      "https://cdn-images-1.medium.com/max/541/1*IfwvdXdue_6unCX5eCnnqg.png",
  },

  {
    id: 2,
    title: "How to create your own project",
    date: "2019-05-12",
    timestamp: "5",
    image:
      "https://res.cloudinary.com/daily-now/image/upload/f_auto,q_auto/v1/posts/7cdf1f74b2746ca3f1fa3496bfa9d2c1",
  },
  {
    id: 3,
    title: "Python Vs Javascript key difference.",
    date: "2021-01-25",
    timestamp: "10",
    image:
      "https://res.cloudinary.com/daily-now/image/upload/f_auto,q_auto/v1/posts/7ebdf6a9948ff3b69d59dcc09af71a20",
  },
  {
    id: 4,
    title: "ICYMI: Recap of The Monthly Dev #1",
    date: "2021-01-15",
    timestamp: "15",
    image:
      "https://res.cloudinary.com/daily-now/image/upload/f_auto,q_auto/v1/posts/d4d32ba08c289bba27daa08322d5a5b0",
  },
  {
    id: 5,
    title: "Philip Walder: Newsletter",
    date: "2020-12-05",
    timestamp: "12",
    image:
      "https://res.cloudinary.com/daily-now/image/upload/f_auto,q_auto/v1/posts/434c9b5550c0386d0cb64a7e6cd4aa90",
  },
  {
    id: 6,
    title: "Progressive Rendering for Better Web App Performance",
    date: "2020-08-12",
    timestamp: "10",
    image:
      "https://res.cloudinary.com/daily-now/image/upload/f_auto,q_auto/v1/posts/5de499d8a1f17e8e3d9c654e3342aff7",
  },
];
