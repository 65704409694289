import React, { useState } from "react";
import tutorialsData from "../handler/tutorialsData";
import { Link } from "react-router-dom";

import Tutorial from "../components/Tutorial";
function Tutorials() {
  const [tutorials, setTutorials] = useState(tutorialsData);

  return (
    <section className="w-full mx-auto md:max-w-6xl px-8 py-5 md:py-20">
      <div className="flex flex-col items-center">
        <h2 className="text-center text-3xl mx-auto font-bold">Tutorials</h2>
        <hr className="bg-blue h-1 w-32" />
      </div>
      <div className="grid grid-cols-1 gap-6 mt-16">
        {tutorials.slice(0, 4).map((tutorial) => (
          <Tutorial key={tutorial.id} tutorial={tutorial} />
        ))}
      </div>
      <div className="flex justify-center md:justify-end w-full mt-10 md:mt-0">
        <Link
          to="/projects"
          className="text-sm bg-blue px-8 py-3 rounded-md shadow-md text-black font-semibold inline-block"
        >
          View More
        </Link>
      </div>
    </section>
  );
}

export default Tutorials;
