import banner from "../img/Banner.png";

function Banner() {
  return (
    <section>
      <figure>
        <img src={banner} alt="" className="w-full h-1/2" />
      </figure>
    </section>
  );
}

export default Banner;
