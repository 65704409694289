import React, { useState } from "react";
import skillData from "../handler/skillData";
import Skill from "../components/Skill";

function Skills() {
  const [skills, setSkills] = useState(skillData);

  return (
    <section className="w-full mx-auto md:max-w-6xl px-8 py-10 md:py-20">
      <div className="flex flex-col items-center">
        <h2 className="text-center text-3xl mx-auto font-bold">Skills</h2>
        <hr className="bg-blue h-1 w-20" />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 mt-16">
        {skills.slice(0, 8).map((skill) => (
          <Skill key={skill.id} skill={skill} />
        ))}
      </div>
    </section>
  );
}

export default Skills;
