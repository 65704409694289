import React from "react";

function Tutorial({ tutorial }) {
  const { title, image, text, icons } = tutorial;
  return (
    <section className="flex items-center space-x-5 px-6 py-2">
      <img className="hidden md:block h-28" src={image} alt="" />
      <div className="space-y-2 text-white">
        <h3 className=" text-lg font-semibold text-white">{title}</h3>
        <p className="text-sm font-semibold ">{text}</p>
        <div className="flex items-center space-x-3">
          {icons?.map((Item) => (
            //
            <img src={Item} alt="" className="h-6" />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Tutorial;
