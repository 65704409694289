import React, { useState } from "react";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { social } from "../handler/navData";

function Header() {
  const [showLinks, setShowLinks] = useState(true);

  return (
    <header className="sticky top-0 z-10 bg-black">
      <div className=" px-8 md:max-w-6xl mx-auto w-full py-4">
        <div className="nav-center">
          <div className="nav-header">
            {/* <figure>
            <Link to="/">
              <img src={logo} alt="" className="h-20" />
            </Link>
          </figure> */}
            <p className="font-bold text-2xl text-white uppercase">Logo</p>
            <button onClick={() => setShowLinks(!showLinks)}>
              <AiOutlineMenu className="md:hidden text-white absolute top-10 md:top-10 right-10 text-2xl cursor-pointer" />
            </button>
          </div>
          <div
            className={`${
              showLinks ? "links-container show-container" : "show-container"
            }`}
          >
            <ul className="links text-base text-white font-semibold md:space-x-4">
              <li className="links">
                <a className="link" href="#" smooth={true} duration={1000}>
                  Projects
                </a>
              </li>
              <li className="links">
                <a
                  className="link"
                  href="#mission"
                  smooth={true}
                  duration={1000}
                >
                  Blogs
                </a>
              </li>
              <li className="links">
                <a className="link" href="#moon" smooth={true} duration={1000}>
                  Story
                </a>
              </li>
              <li className="links">
                <a
                  className="link"
                  href="#roadmap"
                  smooth={true}
                  duration={1000}
                >
                  Tutorials
                </a>
              </li>

              <li className="block md:hidden">
                <ul className="flex space-x-4">
                  {social.map((socialIcon) => {
                    const { id, url, icon } = socialIcon;
                    return (
                      <figure className="" key={id}>
                        <a href={url} target="_blank">
                          <img
                            className="h-6 md:h-full"
                            src={icon}
                            alt="Instagram"
                          />
                        </a>
                      </figure>
                    );
                  })}
                </ul>
              </li>
            </ul>
          </div>

          <div className="social-icons space-x-4">
            {social.map((socialIcon) => {
              const { id, url, icon } = socialIcon;
              return (
                <figure key={id}>
                  <a href={url} target="_blank">
                    <img className="h-5" src={icon} alt="Instagram" />
                  </a>
                </figure>
              );
            })}
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
