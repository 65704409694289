import React from "react";

function Project({ project }) {
  const { id, title, image, text, icons } = project;
  return (
    <section className="flex items-center space-x-5 bg-white rounded-md shadow-md px-6 py-2">
      <img className="hidden md:block h-28" src={image} alt="" />
      <div className="space-y-3">
        <h3 className="text-black text-lg font-semibold">{title}</h3>
        <p className="text-xs font-semibold text-black">{text}</p>
        <div className="flex items-center space-x-3">
          {icons?.map((Item) => (
            //
            <img src={Item} alt="" className="h-6" />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Project;
