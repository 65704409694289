import React, { useState } from "react";
import projectData from "../handler/projectData";

import Project from "../components/Project";
import { Link } from "react-router-dom";

function Projects() {
  const [projects, setProjects] = useState(projectData);

  return (
    <section className="w-full mx-auto md:max-w-6xl px-8 py-5 md:py-20">
      <div className="flex flex-col items-center space-y-8">
        <div className="space-y-20">
          <div className="flex flex-col items-center">
            <h2 className="text-center text-3xl mx-auto font-bold">Projects</h2>
            <hr className="bg-blue h-1 w-32" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {projects.slice(0, 4).map((project) => (
              <Project key={project.id} project={project} />
            ))}
          </div>
        </div>
        <div className="flex justify-center md:justify-end w-full">
          <Link
            to="/projects"
            className="text-sm bg-blue px-8 py-3 rounded-md shadow-md text-black font-semibold inline-block"
          >
            View More
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Projects;
